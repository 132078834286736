export default {
  api: {
    operationSucceed: '操作成功',
    operationFailed: '操作失敗',
    errorTip: '錯誤提示',
    errorMessage: '操作失敗,系統異常!',
    timeoutMessage: '登錄超時,請重新登錄!',
    apiTimeoutMessage: '接口請求超時,請刷新頁面重試!',
    apiRequestFailed: '請求出錯，請稍候重試',
    networkException: '網絡異常',
    networkExceptionMsg: '網絡異常，請檢查您的網絡連接是否正常!',

    errMsg401: '用戶沒有權限（令牌、用戶名、密碼錯誤）!',
    errMsg403: '用戶得到授權，但是訪問是被禁止的。 !',
    errMsg404: '網絡請求錯誤,未找到該資源!',
    errMsg405: '網絡請求錯誤,請求方法未允許!',
    errMsg408: '網絡請求超時!',
    errMsg500: '服務器錯誤,請聯繫管理員!',
    errMsg501: '網絡未實現!',
    errMsg502: '網絡錯誤!',
    errMsg503: '服務不可用，服務器暫時過載或維護!',
    errMsg504: '網絡超時!',
    errMsg505: 'http版本不支持該請求!',
  },
  exception: {
    backLogin: '返回登錄',
    backHome: '返回首頁',
    subTitle403: '抱歉，您無權訪問此頁面。',
    subTitle404: '抱歉，您訪問的頁面不存在。',
    subTitle500: '抱歉，服務器報告錯誤。',
    noDataTitle: '當前頁無數據',
    networkErrorTitle: '網絡錯誤',
    networkErrorSubTitle: '抱歉，您的網絡連接已斷開，請檢查您的網絡！',
  },
  app: {
    title: '王牌商城',
    description: '王牌商城, 遊戲充值, Dmen充值, 代幣充值',
    tip: '提示',
    copyright: 'Ace Playing',
    icp: '京ICP備20009004號',
    address: '公司地址: 北京市朝陽區北三環東路28號',
    account: '我的賬戶',
    changePwd: '修改密碼',
    chargeRecord: '充值記錄',
    helpCenter: '幫助中心',
    chargeNow: '立即充值',
  },
  login: {
    email: '電子郵箱',
    verifyCode: '驗證碼',
    password: '密碼',
    passwordHolder: '密碼（請輸入8～30位數字和字母組合）',
    loginButton: '登錄',
    freeAccount: '免費註冊',
    freeAccountCreate: '免費創建賬號',
    forgetPassword: '忘記密碼?',
    findPassword: '找回密碼',
    findEmailSent: '密碼找回郵件已發送至您的郵箱，請點擊郵件中的鏈接重置密碼',
    forgetFormTitle: '重置密碼',
    oldPassword: '舊密碼',
    newPassword: '新密碼',
    repeatPassword: '请再次輸入密碼',
    policyCheck: '请同意',
    policy: '《王牌隱私協議》',
    hadAccount: '已有賬號?',
    backSignIn: '返回登錄',

    logoutButton: '退出登錄',
    registerButton: '註冊',

    otherSignIn: '其他登錄方式',

    // notify
    loginSucceed: '登錄成功',
    logoutSucceed: '您已退出當前賬號',
    registerSucceed: '註冊成功',
    changePwdSucceed: '密碼修改成功',
    resetPwdSucceed: '密碼重置成功',

    // ValidateMsg
    emailValidateMsg: '請輸入正確的郵箱地址',
    passwordValidateMsg: '請輸入8～30位數字和字母組合',
    verifySentMsg: '驗證碼已發送',
    verifyValidateMsg: '請輸入6位數字驗證碼',
    policyValidateMsg: '同意後才能註冊',
    diffPwdValidateMsg: '兩次輸入密碼不一致',
  },
  help: {
    helpModalTitle: '幫助中心',
    helpTipsTitle: '溫馨提示',
    helpTips_1: '本平台儲值所獲得的虛擬道具，僅適用於“D-MEN：The Defenders”。',
    helpTips_2:
      '在您下訂單時，請您仔細閱讀並確認所購商品的名稱、價格、幣種、數量、規格、產品詳情、下單流程、遞送信息、聯繫方式、支付方式等信息。為避免個人損失，請勿將個人信息透露給他人。',
    serviceTitle: '服務說明',
    service_1:
      '本服務僅面向綁定了“D-MEN：The Defenders”應用的用戶。沒有進行綁定的用戶需要在遊戲中完成綁定操作才能使用本服務。',
    service_2:
      '重要：輸入賬號後，請仔細核對顯示的賬戶信息，確認無誤後請再進行下一步操作！ （若出現支付錯誤賬戶情況，官方將無法為您進行退款操作，敬請諒解！）',
    service_3:
      '付款成功後，系統將發送相應虛擬道具至您的儲值賬戶，請在遊戲內查收。若您在儲值成功30分鐘後未收到購買的虛擬道具，請通過下列方式聯繫客服，我們將及時為您解決問題。',
    contactTitle: '客服渠道列表',
    contactEmail: '客服郵箱',
    contactFacebook: 'Facebook鏈接',
    contactDiscord: 'Discord鏈接',
  },
  charge: {
    title: '商品列表',
    description: '王牌商城, 遊戲充值, Dmen充值, 代幣充值',
    coinSelect: '幣種選擇',
    merchandiseList: '遊戲商品列表',
    buyNow: '立即搶購',
    quickLimit: '限時秒殺搶購',
    buyLimit: '每個角色限購 {limit} 件',
    chooseRole: '選擇角色',
    server: '服務器',
    serverIdHolder: '請輸入服務器名稱',
    roleID: '角色id',
    roleIDHolder: '請輸入角色ID',
    searchResult: '搜索結果',
    searchAgain: '重新搜索',
    addRole: '添加角色',
    roleInfo: '角色信息',
    roleLevel: '{level} 級',
    gameName: '遊戲名稱',
    action: '操作',
    confirmDel: '確認刪除嗎?',
    paymentWay: '支付方式',
    paymentAmount: '支付金額',
    roleLimit: '該角色購買剩餘額度:',
    payNow: '立即付款',
    payJumpTips: '即將跳轉到第三方支付平台, 請稍候...',
    paid: '已完成付款',
  },
  orders: {
    title: '訂單列表',
    description: '訂單列表',
    ordersTitle: '訂單列表',
    searchDate: '查詢日期',
    selectGame: '選擇遊戲',
    allGame: '全部遊戲',
    orderNo: '訂單編號',
    gameName: '遊戲名稱',
    merchandiseName: '商品名稱',
    merchandiseDetail: '商品詳情',
    orderAmount: '訂單金額',
    createAt: '創建時間',
    orderStatus: '訂單狀態',
    statusGoing: '支付中',
    statusSucceed: '支付成功',
    statusUnknown: '請前往充值記錄查看支付結果',
    statusFailed: '支付失敗',
    action: '操作',
    viewDetail: '查看詳情',
    detailTitle: '訂單詳情',
    backToOrders: '返回訂單列表',
    orderInfo: '訂單信息',
    merchandiseInfo: '商品信息',
    price: '單價',
    quantity: '數量',
    discount: '優惠',
    payResult: '支付結果',
    viewOrder: '查看訂單',
  },
  order: {
    title: '訂單詳情',
    description: '訂單詳情',
  },
  notFound: {
    title: '404',
    description: '404',
  },
  result: {
    title: '支付結果',
    description: '支付結果',
  },
};
